import React from 'react'
import { string } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import {
  DICTIONARY_SITENAME,
  DICTIONARY_URL,
  MailingLists,
  PROPTYPE_SITENAME,
  SIGNUP_ERROR_MESSAGE,
  THESAURUS_URL
} from '~client/constants'
import { A } from '~elements'
import { FooterCorpNav, SignUpForm, SocialLinks } from '~exportable'
import dictionaryLogo from '~public/images/dictionary-logo-full.svg'
import thesaurusLogo from '~public/images/thesaurus-logo-full.svg'
import styles from './FooterModule.module.scss'

const FooterModule = ({
  dictionaryUrl,
  selectedSite,
  thesaurusUrl
}) => {
  const isDictionary = selectedSite === DICTIONARY_SITENAME
  const mailingList = isDictionary ? MailingLists.WOTD : MailingLists.SOTD
  const listType = isDictionary ? 'Word' : 'Synonym'
  const signUpHeadline = `Get the ${listType} of the Day every day!`

  const logos = [
    { href: dictionaryUrl, label: DICTIONARY_URL, src: dictionaryLogo },
    { href: thesaurusUrl, label: THESAURUS_URL, src: thesaurusLogo }
  ]

  const logosMap = logos.map(({ href, label, src }, index) => (
    <A
      aria-label={label}
      data-analytics="aa-track"
      data-linklocation="footer"
      data-linkmodule="site-navigation"
      data-linkmodule_position={index + 1}
      data-linkname={label}
      data-linktype="cta"
      data-linkurl={href}
      data-type="sites-menu"
      href={href}
      key={label}
    >
      <img className={styles.logo} role="img" src={src} />
    </A>
  ))

  return (
    <footer className={styles.root} data-type="main-footer-module">
      <section className={styles.logos}>{logosMap}</section>
      <section className={styles.nav}>
        <FooterCorpNav />
      </section>
      <section className={styles.socials}>
        <p className={styles['social-heading']}>Follow us</p>
        <SocialLinks selectedSite={selectedSite} />
        <p>{signUpHeadline}</p>
        <SignUpForm
          errorMessage={SIGNUP_ERROR_MESSAGE}
          isFooterSignUp
          mailingList={mailingList}
        />
      </section>
      <div className={styles.trademark}>© 2024 Dictionary.com, LLC</div>
    </footer>
  )
}

FooterModule.propTypes = {
  dictionaryUrl: string,
  selectedSite: PROPTYPE_SITENAME.isRequired,
  thesaurusUrl: string
}

FooterModule.defaultProps = {
  dictionaryUrl: DICTIONARY_URL,
  thesaurusUrl: THESAURUS_URL
}

export default withStyles(styles)(FooterModule)
