import React from 'react'
import { object, string } from 'prop-types'
import { Modal } from '~elements'

const UpsertWordListModal = ({ heading, modalState, wordList }) => {
  if (!modalState.open) return null

  return (
    <Modal
      data-type="new-word-list-modal"
      heading={heading}
      modalState={modalState}
    >
      <p>
        A version of your word list that identifies you as the author is
        searchable and publicly available.
      </p>
    </Modal>
  )
}

UpsertWordListModal.propTypes = {
  heading: string.isRequired,
  modalState: object.isRequired,
  wordList: object
}

UpsertWordListModal.defaultProps = {
  wordList: null
}

export default UpsertWordListModal
